<script>
import $ from 'jquery';
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import { sparklineChartData, salesDonutChart, radialBarChart } from "./data";
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
  },
  data() {
    return {
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      chartOptions1: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'Brands',
          colorByPoint: true,
          data: [{
            name: 'مكتمل',
            y: 0.0
          }, {
            name: 'مرفوضة',
            y: 0.0
          },
          {
            name: 'قيد الانتظار',
            y: 0.0
          }]
        }]
      },
      chartOptions2: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'Brands',
          colorByPoint: true,
          data: [{
            name: 'مستخدم فرد',
            y: 1
          }, {
            name: 'مستخدم اعمال',
            y: 1
          },
          ]
        }]
      },
      chartOptions: {
        series: [
          {
            type: 'column',
            name: 'Booking Activities',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        title: {
          text: 'عدد الحجوزات في بحسب الشهر',
        },
        exporting: {
          enabled: false, // Hide the export button
        },
        yAxis: {
          title: {
            text: 'عدد حجوزات الطيران', // Y-axis title text
          },
        },

      },
      statData: [
        {
          title: "مستخدمين افراد",
          image: 'fas fa-user-alt',
          value: "1,685",
          subText: "+ 12%",
          color: "success"
        },
        {
          title: "الشركات",
          image: 'far fa-building',
          value: "52,368",
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "المبيعات",
          image: 'fas fa-money-bill-wave',
          value: "15.8",
          subText: "00%",
          color: "info"
        },
        {
          title: "التذاكر",
          image: 'fas fa-ticket-alt',
          value: "2436",
          subText: "+ 84%",
          color: "warning"
        }
      ],
      transactions: [
        {
          id: "#14256",
          image: require("@/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "15/1/2018",
          amount: "$94",
          status: "Delivered"
        },
        {
          id: "#14257",
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Brent Shipley",
          date: "16/1/2019",
          amount: "$112",
          status: "Pending"
        },
        {
          id: "#14258",
          image: require("@/assets/images/users/user-4.jpg"),
          name: "Robert Sitton",
          date: "17/1/2019",
          amount: "$116",
          status: "Delivered"
        },
        {
          id: "#14259",
          image: require("@/assets/images/users/user-5.jpg"),
          name: "Alberto Jackson",
          date: "18/1/2019",
          amount: "$109",
          status: "Cancel"
        },
        {
          id: "#14260",
          image: require("@/assets/images/users/user-6.jpg"),
          name: "David Sanchez",
          date: "15/1/2018",
          amount: "$94",
          status: "Delivered"
        },
        {
          id: "#14261",
          image: require("@/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "15/1/2018",
          amount: "$94",
          status: "Pending"
        }
      ],
      chatMessages: [
        {
          id: 1,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Hello!",
          time: "10:00"
        },
        {
          id: 2,
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Hi, How are you? What about our next meeting?",
          time: "10:01",
          odd: true
        },
        {
          id: 3,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yeah everything is fine",
          time: "10:01"
        },
        {
          id: 4,
          image: require("@/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Wow that's great",
          time: "10:02",
          odd: true
        },
        {
          id: 5,
          image: require("@/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yup!",
          time: "10:03"
        }
      ],
      activityData: [
        {
          date: "Jan 22",
          text: "Responded to need “Volunteer Activities”"
        },
        {
          date: "Jan 20",
          text:
            "At vero eos et accusamus et iusto odio dignissimos ducimus qui deleniti atque... "
        },
        {
          date: "Jan 19",
          text: "Joined the group “Boardsmanship Forum”"
        },
        {
          date: "Jan 17",
          text: "Responded to need “In-Kind Opportunity”"
        },
        {
          date: "Jan 16",
          text: "Sed ut perspiciatis unde omnis iste natus error sit rem."
        }
      ],
      user_count: 0,
      company_count: 0,
      ticket_count: 0,
      sales_count: 0,
      current_month_earn: 0,
      last_month_earn: 0,
      data: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
      },
      options: {
        low: 0,
        showArea: true,
        height: 300
      }
    };
  },
  mounted() {
    $('.pre-loader').show();
    var url = `${this.$appUrl}/api/admin/dashboard/get_info`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.user_count = response.data.data.user_count;
      this.company_count = response.data.data.company_count;
      this.ticket_count = response.data.data.ticket_count;
      this.sales_count = response.data.data.sales_count;
      this.data.series[0] = response.data.data.monthly_earn;
      this.last_month_earn = response.data.data.last_month_earn;
      this.current_month_earn = response.data.data.current_month_earn;
      this.chartOptions2.series.data = [{
        name: 'مستخدم فرد',
        y: this.user_count
      }, {
        name: 'مستخدم اعمال',
        y: this.company_count
      },
      ];
      $('.pre-loader').hide();
    });
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t('Dashboard') }}</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">{{ $t('Welcome to Jsky Dashboard') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-secondary text-white">
          <div class="card-body">
            <div class="mb-3 mt-3">
              <div class="float-start mini-stat-img me-4"><i class="fas fa-user-alt font-25"></i>
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white">مستخدمين افراد</h5>
              <h4 class="fw-medium font-size-24"> {{ user_count }} <i class="mdi mdi-arrow-up text-primary ms-2"></i></h4>
            </div>
            <div class="pt-2">
              <p class="text-white-50 mb-0 mt-1"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-secondary text-white">
          <div class="card-body">
            <div class="mb-3 mt-3">
              <div class="float-start mini-stat-img me-4"><i class="far fa-building font-25"></i>
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white">الشركات</h5>
              <h4 class="fw-medium font-size-24"> {{ company_count }} <i class="mdi mdi-arrow-up text-primary ms-2"></i>
              </h4>
            </div>
            <div class="pt-2">
              <p class="text-white-50 mb-0 mt-1"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-secondary text-white">
          <div class="card-body">
            <div class="mb-3 mt-3">
              <div class="float-start mini-stat-img me-4"><i class="fas fa-money-bill-wave font-25"></i></div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white">المبيعات</h5>
              <h4 class="fw-medium font-size-24"> {{ sales_count }} <i class="mdi mdi-arrow-up text-primary ms-2"></i>
              </h4>
            </div>
            <div class="pt-2">
              <p class="text-white-50 mb-0 mt-1"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-secondary text-white">
          <div class="card-body">
            <div class="mb-3 mt-3">
              <div class="float-start mini-stat-img me-4"><i class="fas fa-ticket-alt font-25"></i>
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white">التذاكر</h5>
              <h4 class="fw-medium font-size-24"> {{ ticket_count }} <i class="mdi mdi-arrow-up text-primary ms-2"></i>
              </h4>
            </div>
            <div class="pt-2">
              <p class="text-white-50 mb-0 mt-1"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">المبيعات الشهرية</h4>
            <div class="row">
              <div class="col-lg-7">
                <div>
                  <chartist ratio="ct-chart" :data="data" :options="options" type="Line"></chartist>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="row">
                  <div class="col-md-6">
                    <div class="text-center">
                      <p class="text-muted mb-4">هذا الشهر</p>
                      <h3>{{ current_month_earn }}</h3>
                      <p class="text-muted mb-4"></p>
                      <apexchart class="apex-charts" height="150" type="radialBar" :series="[0]"
                        :options="radialBarChart.chartOptions"></apexchart>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="text-center">
                      <p class="text-muted mb-4">الشهر الماضي</p>
                      <h3>{{ last_month_earn }}</h3>
                      <p class="text-muted mb-4"></p>
                      <!-- Radial bar chart  -->
                      <apexchart class="apex-charts" height="150" type="radialBar" :series="[0]"
                        :options="radialBarChart.chartOptions"></apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">الحجوزات الشهرية</h4>
            <div class="row">
              <div class="col-md-12"></div>
              <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">الحجوزات</h4>
            <div class="row">
              <div class="col-md-12"></div>
              <highcharts class="hc" :options="chartOptions1" ref="chart"></highcharts>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">المستخدمين</h4>
            <div class="row">
              <div class="col-md-12"></div>
              <highcharts class="hc" :options="chartOptions2" ref="chart"></highcharts>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <!-- end row -->
  </Layout>
</template>